import {
    ref,
    reactive
} from 'vue';
import axios from '@/axios';
import store from '@/store';

export default function useStudent() {
    const errors = ref([]); //ref: to make it reactive
    const msgs = ref([]);
    const students = ref([]);
    const student = ref([]);
    const lists = reactive({});


    const getStudents = async (data = {}) => {
        try {
            let response = await axios.post('/api/students/get', data);
            students.value = response.data.data.students;
            lists.years = response.data.data.years;
            lists.levels = response.data.data.levels;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }

    const getStudent = async (id) => {
        try {
            let response = await axios.get('/api/students/' + id);
            student.value = response.data.data.student;
            lists.years = response.data.data.years;
            lists.classes = response.data.data.classes;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }

    const mangeLockPaymentProfileToStd = async (action, data) => {
        try {
            let response = await axios.post('/api/students/actions/' + action, data);
            msgs.value = response.data.message;
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
        }
    }

    const getLists = async () => {
        try {
            let response = await axios.post('/api/students/lists');
            lists.years = response.data.data.years;
            lists.installments = response.data.data.installments;
            lists.countries = response.data.data.countries
            lists.religions = response.data.data.religions
            lists.nationalities = response.data.data.nationalities;
            lists.languages = response.data.data.languages
            lists.transportation_types = response.data.data.transportation_types
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }

    const storeAddDeductFees = async (data) => {
        errors.value = ''; //
        try {
            let response = await axios.post('/api/other_fees', data);
            return response.data.data;
        } catch (e) {
            //errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }
    }

    const UpdateStudent = async (data, id) => {
        errors.value = ''; //
        try {
            data.append("_method", 'PATCH');
            let response = await axios.post('/api/students/' + id, data);
            return response.data.data;
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }
    }

    const downloadStudentFile = async (filepath, filetype) => {
        try {

            axios({
                url: '/api/students/download_file/',
                method: 'POST',
                data: {
                    filepath: filepath
                },
                responseType: 'blob',
            }).then((response) => {

                let fileExtension = response.data.type.split('/').pop();

                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'std-' + filetype + '.' + fileExtension);
                document.body.appendChild(fileLink);

                fileLink.click();
            });
        } catch (e) {
            await store.dispatch("handleException", e);

        }
    }

    const sendCurrentTermResultsReport = async (student_id) => {
        try {
            let response = await axios.post('/api/send-current-term-results-report/' + student_id);
            return response.data.data;
        } catch (e) {
            return 'fail';
        }
    };


    const transferStudentClass = async (data) =>{
        try {
            let response = await axios.post('/api/students/transfer-std-class', data);
            msgs.value = response.data.message;
            errors.value = [];
        } catch (e) {
            msgs.value = [];
            errors.value = await store.dispatch("handleException", e);
        }
    }

    return {
        lists,
        msgs,
        errors,
        student,
        students,
        getStudents,
        getStudent,
        transferStudentClass,
        mangeLockPaymentProfileToStd,
        sendCurrentTermResultsReport,
        getLists,
        storeAddDeductFees,
        UpdateStudent,
        downloadStudentFile
    }
}