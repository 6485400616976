<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-sm-6 p-md-0">
        <div class="welcome-text">
          <h4>Students</h4>
        </div>
      </div>
      <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Student-Affairs' }">Student Affairs</router-link>
          </li>
          <li class="breadcrumb-item active">Students</li>
        </ol>
      </div>
    </div>

    <div class="row tab-content">
      <div id="" class="tab-pane fade active show col-lg-12">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">List All</h4>
          </div>
          <div class="card-body">
            <div class="search-block row" style="padding-bottom: 15px">
              <div class="col-md-12">
                <form @submit.prevent="submitSearchForm()" class="form-inline">
                  <!-- Student Code -->
                  <div class="form-group mb-2 mr-2">
                    <label class="mr-2">Code</label>
                    <input type="text" class="form-control" v-model="search_fields.code" name="code"
                      placeholder="Student`s Code" />
                  </div>

                  <!-- study Years -->
                  <div class="form-group mb-2 mr-2">
                    <label class="mr-2">Academic Year</label>
                    <select class="form-control" name="year_id" v-model="search_fields.year_id">
                      <option value="" disabled selected>
                        Nothing Selected
                      </option>
                      <option v-for="year in lists.years" :key="year.id" :value="year.id">
                        {{ year.value }}
                      </option>
                    </select>
                  </div>

                  <!-- levels -->
                  <div class="form-group mb-2 mr-2">
                    <label class="mr-2">Level</label>
                    <select class="form-control" name="year_id" @change="filterClasses($event)"
                      v-model="search_fields.level_id">
                      <option value="" disabled selected>
                        Nothing Selected
                      </option>
                      <option v-for="level in lists.levels" :key="level.id" :value="level.id">
                        {{ level.value }}
                      </option>
                    </select>
                  </div>

                  <!-- Classes -->
                  <div class="form-group mb-2 mr-2">
                    <label class="mr-2">Class</label>
                    <select class="form-control" name="year_id" v-model="search_fields.class_id">
                      <option value="" disabled selected>
                        Nothing Selected
                      </option>
                      <option v-for="classData in classes" :key="classData.id" :value="classData.id">
                        {{ classData.name }}
                      </option>
                    </select>
                  </div>

                  <!-- Free search input -->
                  <div class="form-group mb-2 mr-2">
                    <input type="text" class="form-control" v-model="search_fields.free_search_input"
                      name="free_search_input" placeholder="Any Data" />
                  </div>
                  <div class="form-group mb-2 mr-2">
                    <button name="search" type="submit" class="btn btn-primary" value="Filter" onclick="this.blur();">
                      Filter
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div class="table-responsive">

              <EasyDataTable :loading="loading" :rows-per-page="10" :rows-items="[25, 50, 100]" :headers="headers"
                :items="students" alternating buttons-pagination>

                <template #item-student_code="{ user }">{{ user.code }}</template>
                <template #item-student_name="{ user }">{{ user.fullname }}</template>
                <template #item-student_email="{ user }">{{ user.email }}</template>
                <template #item-student_gender="{ user }">{{ user.gender }}</template>
                <template #item-actions="{ id }">
                  <div>
                    <router-link v-can="'edit_students'" :to="{
                      name: 'Student-Affairs-Students-Edit',
                      params: { id: id },
                    }" title="Edit" class="mr-4"><i class="fa fa-pencil color-muted"></i>
                    </router-link>
                    <router-link v-can="'view_students'" :to="{
                      name: 'Student-Affairs-Students-single',
                      params: { id: id },
                    }" title="View" class="mr-4"><i class="fa fa-eye color-muted"></i>
                    </router-link>
                  </div>
                </template>
              </EasyDataTable>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useStudent from "@/composables/students";
import { onMounted, onBeforeMount, inject, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import EasyDataTable from "vue3-easy-data-table";
import 'vue3-easy-data-table/dist/style.css';
export default {
  components: {
    EasyDataTable,
  },
  setup() {
    const hasPermission = inject("hasPermission");
    const router = useRouter();
    const classes = ref([]);
    const loading = ref(true);
    const search_fields = reactive({
      code: "",
      year_id: "",
      level_id: "",
      class_id: "",
      free_search_input: "",
    });

    onBeforeMount(() => {
      //first: check the user Permission
      if (!hasPermission("access_students"))
        router.push({
          name: "401",
        });
    });

    const { students, getStudents, lists, msgs, errors } = useStudent();

    onMounted(() => {
      getStudents().then(() => {
        loading.value = false;
      });
    });

    const submitSearchForm = async () => {
      loading.value = true;
      await getStudents(search_fields).then(() => {
        loading.value = false;
      });
    };

    const filterClasses = (event) => {
      lists.levels.map(function (level) {
        if (level.id == event.target.value) {
          classes.value = level.classes;
        }
      });
    };

    const headers = ref([
      { text: "Code", value: "student_code" },
      { text: "Student Name", value: "student_name", sortable: true },
      { text: "Email", value: "student_email", sortable: true },
      { text: "Gender", value: "student_gender", sortable: true },
      { text: "Actions", value: "actions", sortable: true },
    ]);

    return {
      headers,
      loading,
      submitSearchForm,
      filterClasses,
      classes,
      search_fields,
      students,
      getStudents,
      lists,
      msgs,
      errors,
    };
  },
};
</script>

<style>
.vue3-easy-data-table__header tr th .header {
  position: unset !important;
  color: black !important;
  text-align: left !important;
  background-color: #fafafa !important;
  padding-left: 1px !important;
  height: 2rem !important;
}
</style>
